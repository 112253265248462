import { Stack, useTheme, Button } from "@mui/material";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { LoadingButton } from "@mui/lab";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Fragment, useEffect, useMemo, useState } from "react";
import { orderBy } from "lodash";
import { isSameDay } from "date-fns";
import IssueDetail from "./IssueDetail";
import Whisker from "./Whisker";
import { formatDate, formatMonitorType } from "./utils";
import styles from "./MonitorTimelineBody.module.css";
import AssignButton from "./AssignButton";

import {
  APIMonitoringEnrollment,
  APIMonitorActivity,
  APIMonitorActivityType,
  APIMonitorType,
  APIMonitoringEnrollmentMonitorConfiguration,
} from "../../../types/APIMonitoringEnrollment";
import { useTrueBizApi } from "../../../api";
import { useShowNetworkFailureToast } from "../../NetworkFailureToast";

function PauseResumeButton({
  domain,
  monitorType,
  isEnabled,
}: {
  domain: string;
  monitorType: APIMonitorType;
  isEnabled: boolean;
}) {
  const api = useTrueBizApi();
  const queryClient = useQueryClient();
  const showToast = useShowNetworkFailureToast();

  const configurationMutation = useMutation({
    mutationFn: async () =>
      api.setMonitorEnabled({
        domain,
        monitor_type: monitorType,
        is_enabled: !isEnabled,
      }),
    mutationKey: ["configureEnrollment", domain, monitorType, !isEnabled],
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: ["getMonitor", domain] });
      const previous = queryClient.getQueryData(["getMonitor", domain]);

      if (previous === undefined) return; // query hasn't been populated yet

      queryClient.setQueryData(["getMonitor", domain], (old: any) => {
        const updated = {
          ...old,
          monitors: old.monitors.map(
            (monitor: APIMonitoringEnrollmentMonitorConfiguration) =>
              monitor.type !== monitorType
                ? monitor
                : { ...monitor, is_enabled: !isEnabled }
          ),
        };

        return updated;
      });

      return { previous };
    },
    onSuccess: async (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["getMonitor", domain],
        refetchType: "all",
      });
    },
    onError: (err, variables, context) => {
      if (context?.previous) {
        queryClient.setQueryData(["getMonitor", domain], context.previous);
      }
      showToast();
    },
  });

  return (
    <LoadingButton
      size="small"
      loading={configurationMutation.isPending}
      onClick={() => configurationMutation.mutate()}
    >
      {isEnabled ? "Pause" : "Resume"}
    </LoadingButton>
  );
}

function Header({ enrollment }: { enrollment: APIMonitoringEnrollment }) {
  const theme = useTheme();

  return (
    <div className={styles.header}>
      <h2 style={{ marginTop: 0, lineHeight: 1, marginBottom: "0.25em" }}>
        <span>
          <a href={`//${enrollment.domain}`} target="_blank" rel="noreferrer">
            {enrollment.domain} <OpenInNewIcon fontSize="small" />
          </a>
        </span>
      </h2>
      <div className={styles.columns}>
        <div>
          <div>
            <Button
              component={Link}
              to={`/search/${enrollment.domain}/most_recent`}
              size="small"
              target="_blank"
              rel="noreferrer"
            >
              See most recent web presence review
            </Button>
          </div>
          {(enrollment.unresolved_anomaly_count || 0) > 0 && (
            <div className={styles.assignedTo}>
              Assigned to:{" "}
              <AssignButton
                title="Assign issue"
                label={
                  enrollment.unresolved_anomaly_assignments[0]?.assigned_to
                    ? enrollment.unresolved_anomaly_assignments[0]?.assigned_to
                        ?.email || "A TrueBiz user"
                    : "unassigned"
                }
                currentAssigneeId={
                  enrollment.unresolved_anomaly_assignments[0]?.assigned_to?.id
                }
                monitorDomain={enrollment.domain}
              />
            </div>
          )}
          {enrollment.external_ref_id && (
            <div
              style={{
                color: theme.palette.grey[700],
                fontSize: theme.typography.subtitle2.fontSize,
              }}
            >
              Reference ID <code>{enrollment.external_ref_id}</code>
            </div>
          )}
          <div className={styles.meta}>
            <div className={styles.runInfo}>
              <div className={styles.next}>
                Next Run:{" "}
                {enrollment.next_run ? (
                  <strong>{formatDate(enrollment.next_run)}</strong>
                ) : (
                  "unscheduled"
                )}
              </div>
              <div className={styles.previous}>
                Previous Run:{" "}
                {enrollment.last_run ? (
                  <strong>{formatDate(enrollment.last_run)}</strong>
                ) : (
                  "never"
                )}
              </div>
            </div>
          </div>
        </div>

        <div>
          <strong>Monitored for</strong>
          {enrollment.monitors.map((monitor) => (
            <div
              key={monitor.type}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: !monitor.is_enabled
                  ? theme.palette.grey[500]
                  : undefined,
              }}
            >
              {formatMonitorType(monitor.type)}{" "}
              {monitor.is_enabled && monitor.frequency && (
                <>every {monitor.frequency?.days} days</>
              )}
              {!monitor.is_enabled && <>(paused)</>}
              <PauseResumeButton
                domain={enrollment.domain}
                isEnabled={monitor.is_enabled}
                monitorType={monitor.type}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export interface Props {
  monitor: APIMonitoringEnrollment;
  focusedNotificationId?: UUID | null | undefined;
}

export default function MonitorTimelineBody({
  monitor,
  focusedNotificationId,
}: Props) {
  // block lazy loading while we're scrolling, so we don't accidentally trigger a bunch of queries
  // while we're scrolling past
  const [suspendIssueLoading, setSuspendIssueLoading] = useState(
    focusedNotificationId != null
  );
  useEffect(() => {
    if (!focusedNotificationId) return;

    const el = document.getElementById(`issue_${focusedNotificationId}`);
    if (!el) return;

    const intersectionObserver = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          setSuspendIssueLoading(false);
          intersectionObserver.disconnect();
          return;
        }
      }
    });

    intersectionObserver.observe(el);

    el.scrollIntoView({
      behavior: "instant" as ScrollBehavior,
      block: "nearest",
    });
  }, [focusedNotificationId]);

  const groupedActivity = useMemo(() => {
    const grouped: APIMonitorActivity[][] = [];

    let currentGroup: APIMonitorActivity[] = [];
    for (const event of orderBy(monitor.activity, ["created_at"], ["desc"])) {
      if (!currentGroup.length) {
        currentGroup.push(event);
        continue;
      }

      if (isSameDay(currentGroup[0].created_at, event.created_at)) {
        currentGroup.push(event);
        continue;
      }

      // different day, start a new group
      grouped.push(currentGroup);
      currentGroup = [event];
    }

    if (currentGroup.length) {
      grouped.push(currentGroup);
    }

    return grouped;
  }, [monitor.activity]);

  return (
    <Stack minHeight="600px" alignItems="center" justifyContent="flex-start">
      <Header enrollment={monitor} />
      {groupedActivity.map((eventGroup, i, eventGroups) => {
        const isLastEvent = i === eventGroups.length - 1;

        const systemEvents = orderBy(
          eventGroup.filter((e) => e.type !== APIMonitorActivityType.ENQUIRY),
          ["created_at"],
          ["desc"]
        );
        const anomalies = orderBy(
          eventGroup.flatMap((event) => {
            if (event.type !== APIMonitorActivityType.ENQUIRY) return [];
            const enquiryIssues = monitor.anomalies.filter((anomaly) =>
              event.anomaly_ids.includes(anomaly.id)
            );
            return enquiryIssues;
          }),
          ["created_at"],
          ["desc"]
        );

        if (!eventGroup.length) return null;
        if (!systemEvents.length && !anomalies.length) return null;
        const groupDate = eventGroup[0].created_at;

        return (
          <Stack key={groupDate} sx={{ width: "100%", mt: 1 }}>
            <div className={styles.timestamp}>{formatDate(groupDate)}</div>
            {anomalies.length > 0 && (
              <>
                <div className={styles.description}>
                  {anomalies.length} issue
                  {anomalies.length === 1 ? "" : "s"} detected
                </div>
              </>
            )}
            {anomalies.length > 0 && (
              <Stack sx={{ width: "100%" }}>
                <Stack>
                  {anomalies.map((anomaly, i, allAnomalies) => {
                    const isLastAnomaly = i === allAnomalies.length - 1;
                    return (
                      <Fragment key={anomaly.id}>
                        <Stack
                          alignItems="center"
                          sx={
                            anomaly.id === focusedNotificationId
                              ? {
                                  borderRadius: "10px",
                                  border: "1px solid #ccc",
                                  background: "rgba(30, 30, 30, 0.1)",
                                }
                              : {}
                          }
                          pt={1}
                        >
                          <IssueDetail
                            id={`issue_${anomaly.id}`}
                            issue={anomaly}
                            focused={anomaly.id === focusedNotificationId}
                            suspendLoading={
                              anomaly.id === focusedNotificationId
                                ? false
                                : suspendIssueLoading
                            }
                          />
                        </Stack>
                        {!isLastAnomaly && <Whisker />}
                      </Fragment>
                    );
                  })}
                </Stack>
                {!isLastEvent && <Whisker />}
              </Stack>
            )}
            {systemEvents.map((event) => {
              if (event.type === APIMonitorActivityType.ENROLLED) {
                return (
                  <Stack key={event.id} sx={{ width: "100%" }}>
                    <Stack>
                      <div className={styles.description}>
                        Enrolled in monitoring
                      </div>
                    </Stack>
                    {!isLastEvent && <Whisker />}
                  </Stack>
                );
              }

              if (event.type === APIMonitorActivityType.MODIFIED) {
                return (
                  <Stack key={event.id} sx={{ width: "100%" }}>
                    <Stack>
                      <div className={styles.description}>
                        Enrollment modified
                      </div>
                    </Stack>
                    {!isLastEvent && <Whisker />}
                  </Stack>
                );
              }

              if (event.type === APIMonitorActivityType.UNENROLLED) {
                return (
                  <Stack key={event.id} sx={{ width: "100%" }}>
                    <Stack>
                      <div className={styles.description}>
                        Enrolled in monitoring
                      </div>
                    </Stack>
                    {!isLastEvent && <Whisker />}
                  </Stack>
                );
              }

              if (process.env.NODE_ENV === "development") {
                console.error(
                  `Got unknown activity on monitor ${monitor.domain}. Type: ${
                    (event as any)?.type
                  }; Id: ${(event as any)?.id} !`
                );
              }

              return null;
            })}
          </Stack>
        );
      })}
    </Stack>
  );
}
