import { useId } from "react";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import type { SelectProps } from "@mui/material/Select";

export interface Props {
  value?: number | null | undefined;
  onChange?: SelectProps["onChange"];
  name?: SelectProps["name"];
}

export default function FrequencySelect({ name, value, onChange }: Props) {
  const labelId = useId();

  return (
    <FormControl fullWidth>
      <InputLabel id={labelId}>Scan every</InputLabel>
      <Select
        value={value}
        label="Scan every"
        labelId={labelId}
        onChange={onChange}
        name={name}
      >
        <MenuItem value={7}>7 days</MenuItem>
        <MenuItem value={30}>30 days</MenuItem>
      </Select>
    </FormControl>
  );
}
