import { useCallback } from "react";

import { identity, isEqual } from "lodash";

import {
  ExportRow,
  ExportStatus,
  TableOrderBy,
  TableFilterBy,
  TableRow,
  TableStatus,
  TableMonitorType,
} from "../MonitorsTable/types";
import { useTrueBizApi } from "../../../api";
import {
  APIMonitoringEnrollment,
  APIMonitoringEnrollmentFilterBy,
  APIMonitoringEnrollmentOrderBy,
  APIMonitorStatusType,
  APIMonitorType,
} from "../../../types/APIMonitoringEnrollment";
import APIMonitoringAnomaly, {
  APIAnomalyStatus,
} from "../../../types/APIAnomaly";

export const serverStatusToExportStatus = (
  serverStatus: APIAnomalyStatus
): ExportStatus => {
  switch (serverStatus) {
    case APIAnomalyStatus.ASSIGNED:
      return ExportStatus.ASSIGNED;
    case APIAnomalyStatus.OPEN:
      return ExportStatus.OPEN;
    case APIAnomalyStatus.RESOLVED:
      return ExportStatus.RESOLVED;
  }
};

export const serverAnomalyToExport = (
  anomaly: APIMonitoringAnomaly
): ExportRow => ({
  id: anomaly.id,
  status: serverStatusToExportStatus(anomaly.status),
  date: anomaly.created_at,
  domain: anomaly.domain,
  alerts: anomaly.detections.flatMap((d) => d.alerts).map((a) => a.type),
  messages: anomaly.detections
    .flatMap((d) => d.alerts)
    .map((a) => a.description),
  assignee: anomaly.assignment?.assigned_to,
  acknowledged_reason: anomaly.resolution?.type,
  acknowledged_by: (anomaly.resolution?.resolved_by?.email ||
    (anomaly.resolution?.resolved_by as any)?.external_ref_id) as any,
  acknowledged_at: anomaly.resolution?.created_at,
  acknowledged_notes: anomaly.resolution?.description,
});

export const useGetIssuesByIds = () => {
  const api = useTrueBizApi();

  return useCallback(
    async (ids: string[]) => {
      // this is a hack for now, eventually we should offload this
      // to a server-side csv generator

      const limit = 50;
      let offset = 0;
      let pagesRequested = 0;
      let done = false;

      const issues: ExportRow[] = [];

      do {
        const response = await api.getAnomalies({
          limit,
          offset,
          filterBy: [{ column: "id", operator: "in", value: ids }],
        });

        issues.push(...response.anomalies.map(serverAnomalyToExport));

        if (response.offset + response.limit >= response.count) {
          done = true;
          break;
        }

        offset = response.offset + response.limit;
        pagesRequested++;
        await wait();
      } while (pagesRequested < 500);

      if (!done) {
        throw new Error("Too many pages requested!");
      }

      return { issues };
    },
    [api]
  );
};

export const wait = async (timeout = 500) => {
  await new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
};

export const tableOrderByToServerOrderBy = (
  tableOrderBy: TableOrderBy[] = []
): APIMonitoringEnrollmentOrderBy[] => {
  const serverOrderBy: APIMonitoringEnrollmentOrderBy[] = [];

  for (const ordering of tableOrderBy) {
    switch (ordering) {
      case "alerts":
        serverOrderBy.push(APIMonitoringEnrollmentOrderBy.ALERTS_ASC);
        continue;
      case "-alerts":
        serverOrderBy.push(APIMonitoringEnrollmentOrderBy.ALERTS_DESC);
        continue;
      case "assigned_to":
        serverOrderBy.push(APIMonitoringEnrollmentOrderBy.ASSIGNED_TO_ASC);
        continue;
      case "-assigned_to":
        serverOrderBy.push(APIMonitoringEnrollmentOrderBy.ASSIGNED_TO_DESC);
        continue;
      case "last_run":
        serverOrderBy.push(APIMonitoringEnrollmentOrderBy.LAST_RUN_ASC);
        continue;
      case "-last_run":
        serverOrderBy.push(APIMonitoringEnrollmentOrderBy.LAST_RUN_DESC);
        continue;
      case "monitor_types":
        serverOrderBy.push(APIMonitoringEnrollmentOrderBy.MONITOR_TYPES_ASC);
        continue;
      case "-monitor_types":
        serverOrderBy.push(APIMonitoringEnrollmentOrderBy.MONITOR_TYPES_DESC);
        continue;
      case "next_run":
        serverOrderBy.push(APIMonitoringEnrollmentOrderBy.NEXT_RUN_ASC);
        continue;
      case "-next_run":
        serverOrderBy.push(APIMonitoringEnrollmentOrderBy.NEXT_RUN_DESC);
        continue;
      case "domain":
        serverOrderBy.push(APIMonitoringEnrollmentOrderBy.DOMAIN_ASC);
        continue;
      case "-domain":
        serverOrderBy.push(APIMonitoringEnrollmentOrderBy.DOMAIN_DESC);
        continue;
      case "status":
        serverOrderBy.push(APIMonitoringEnrollmentOrderBy.STATUS_ASC);
        continue;
      case "-status":
        serverOrderBy.push(APIMonitoringEnrollmentOrderBy.STATUS_DESC);
        continue;
      default:
        if (process.env.NODE_ENV === "development") {
          console.error(
            `Received request to order table column by unsupported ordering "${ordering}"`
          );
        }
    }
  }

  return serverOrderBy;
};

export const tableStatusToServerStatus = (
  tableStatus: TableStatus
): APIMonitorStatusType => {
  switch (tableStatus) {
    case TableStatus.ALL_CLEAR:
      return APIMonitorStatusType.ALL_CLEAR;
    case TableStatus.ALERT:
      return APIMonitorStatusType.ALERT;
    case TableStatus.ALERT_AND_PENDING:
      return APIMonitorStatusType.ALERT_AND_PENDING;
    case TableStatus.PENDING:
      return APIMonitorStatusType.PENDING;
  }
};

export const tableMonitorTypeToServerMonitorType = (
  tableMonitorType: TableMonitorType
): APIMonitorType => {
  switch (tableMonitorType) {
    case TableMonitorType.BUSINESS_CLOSURE:
      return APIMonitorType.BUSINESS_CLOSURE;
    case TableMonitorType.CONNECTED_ENTITIES:
      return APIMonitorType.CONNECTED_ENTITIES;
    case TableMonitorType.WEBSITE_CONTENT:
      return APIMonitorType.WEBSITE_CONTENT;
  }
};

export const tableFilterByToServerFilterBy = (
  tableFilterBy: TableFilterBy[] = []
): APIMonitoringEnrollmentFilterBy[] => {
  const serverFilterBy: APIMonitoringEnrollmentFilterBy[] = [];

  for (const filtering of tableFilterBy) {
    if (filtering.column === "alerts" && filtering.operator === "in") {
      serverFilterBy.push({
        column: "alerts",
        operator: "in",
        value: filtering.value,
      });
    }

    if (filtering.column === "assigned_to" && filtering.operator === "in") {
      serverFilterBy.push({
        column: "assigned_to",
        operator: "in",
        value: filtering.value,
      });
    }

    if (filtering.column === "last_run" && filtering.operator === "gt") {
      serverFilterBy.push({
        column: "last_run",
        operator: "after",
        value: filtering.value.toISOString(),
      });
    }
    if (filtering.column === "last_run" && filtering.operator === "gte") {
      serverFilterBy.push({
        column: "last_run",
        operator: "after",
        value: filtering.value.toISOString(),
      });
    }
    if (filtering.column === "last_run" && filtering.operator === "lt") {
      serverFilterBy.push({
        column: "last_run",
        operator: "before",
        value: filtering.value.toISOString(),
      });
    }
    if (filtering.column === "last_run" && filtering.operator === "lte") {
      serverFilterBy.push({
        column: "last_run",
        operator: "before",
        value: filtering.value.toISOString(),
      });
    }

    if (filtering.column === "next_run" && filtering.operator === "gt") {
      serverFilterBy.push({
        column: "last_run",
        operator: "after",
        value: filtering.value.toISOString(),
      });
    }
    if (filtering.column === "next_run" && filtering.operator === "gte") {
      serverFilterBy.push({
        column: "next_run",
        operator: "after",
        value: filtering.value.toISOString(),
      });
    }
    if (filtering.column === "next_run" && filtering.operator === "lt") {
      serverFilterBy.push({
        column: "next_run",
        operator: "before",
        value: filtering.value.toISOString(),
      });
    }
    if (filtering.column === "next_run" && filtering.operator === "lte") {
      serverFilterBy.push({
        column: "next_run",
        operator: "before",
        value: filtering.value.toISOString(),
      });
    }

    if (filtering.column === "domain" && filtering.operator === "in") {
      serverFilterBy.push({
        column: "domain",
        operator: "in",
        value: filtering.value,
      });
    }

    if (filtering.column === "status" && filtering.operator === "in") {
      serverFilterBy.push({
        column: "status",
        operator: "in",
        value: filtering.value.map(tableStatusToServerStatus),
      });
    }

    if (filtering.column === "monitor_types" && filtering.operator === "in") {
      serverFilterBy.push({
        column: "monitor_types",
        operator: "in",
        value: filtering.value.map(tableMonitorTypeToServerMonitorType),
      });
    }
  }

  return serverFilterBy;
};

export function partitionBy<T>(
  arr: T[],
  pred: (t: T) => any = identity,
  equal = isEqual
): T[][] {
  // like https://clojuredocs.org/clojure.core/partition-by

  if (!arr.length) {
    return [];
  }

  const result: T[][] = [];

  let lastTestValue = pred(arr[0]);
  let buff: T[] = [];

  for (const a of arr) {
    const testValue = pred(a);

    if (equal(testValue, lastTestValue)) {
      buff.push(a);
      continue;
    }

    result.push(buff);
    lastTestValue = testValue;
    buff = [a];
  }

  if (buff.length) {
    result.push(buff);
  }

  return result;
}

export const serverStatusToTableStatus = (
  serverStatus: APIMonitorStatusType
): TableStatus => {
  switch (serverStatus) {
    case APIMonitorStatusType.ALL_CLEAR:
      return TableStatus.ALL_CLEAR;
    case APIMonitorStatusType.ALERT:
      return TableStatus.ALERT;
    case APIMonitorStatusType.ALERT_AND_PENDING:
      return TableStatus.ALERT_AND_PENDING;
    case APIMonitorStatusType.PENDING:
      return TableStatus.PENDING;
  }
};

export const serverMonitorTypeToTableMonitorType = (
  serverMonitorType: APIMonitorType
): TableMonitorType => {
  switch (serverMonitorType) {
    case APIMonitorType.BUSINESS_CLOSURE:
      return TableMonitorType.BUSINESS_CLOSURE;
    case APIMonitorType.CONNECTED_ENTITIES:
      return TableMonitorType.CONNECTED_ENTITIES;
    case APIMonitorType.WEBSITE_CONTENT:
      return TableMonitorType.WEBSITE_CONTENT;
  }
};

export const serverEnrollmentToTableMonitor = (
  enrollment: APIMonitoringEnrollment
): TableRow => ({
  ...enrollment,
  id: enrollment.domain,
  alerts: enrollment.unresolved_anomaly_alerts.map((alert) => alert.type),
  assigned_to: enrollment.unresolved_anomaly_assignments,
  monitor_types: enrollment.monitors.map((m) =>
    serverMonitorTypeToTableMonitorType(m.type)
  ),
  status: serverStatusToTableStatus(enrollment.status),
});

export const useGetIssuesByMonitorDomains = () => {
  const api = useTrueBizApi();

  return useCallback(
    async (domains: string[]) => {
      // this is a hack for now, eventually we should offload this
      // to a server-side csv generator

      const limit = 50;
      let offset = 0;
      let pagesRequested = 0;
      let done = false;

      const issues: ExportRow[] = [];

      do {
        const response = await api.getAnomalies({
          limit,
          offset,
          filterBy: [{ column: "domain", operator: "in", value: domains }],
        });

        issues.push(...response.anomalies.map(serverAnomalyToExport));

        if (response.offset + response.limit >= response.count) {
          done = true;
          break;
        }

        offset = response.offset + response.limit;
        pagesRequested++;
        await wait();
      } while (pagesRequested < 500);

      if (!done) {
        throw new Error("Too many pages requested!");
      }

      return { issues };
    },
    [api]
  );
};
