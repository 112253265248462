import { Box, Divider, Grid, Typography, useTheme } from "@mui/material";
import {
  ThumbDownOffAlt as ThumbDownOffAltIcon,
  ThumbUpOffAlt as ThumbUpOffAltIcon,
} from "@mui/icons-material";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import APIApplication, {
  APIApplicationDescriptionMatchType,
} from "../../../types/APIApplication";

export interface Props {
  description: APIApplication["description"];
}

export default function SubmittedDescription({ description }: Props) {
  const theme = useTheme();
  return (
    <Box sx={{ mb: 3 }}>
      <Box sx={{ minHeight: 55 }}>
        <Typography
          sx={{ fontWeight: 700, fontSize: 18 }}
          variant="subtitle1"
          component="span"
          marginBottom={1}
        >
          Description
        </Typography>

        <Typography
          sx={{ ml: 2, overflowWrap: "break-word" }}
          variant="subtitle1"
          component="span"
        >
          {description?.text}
        </Typography>
      </Box>
      <Box sx={{ pr: 3, mb: 3 }}>
        <Divider />
      </Box>

      {description ? (
        <Grid container spacing={2}>
          <Box
            ml={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <strong>Matches business:</strong>
            {description.match_type ===
            APIApplicationDescriptionMatchType.FULL_MATCH ? (
              <Typography
                sx={{ color: theme.palette.success.light, ml: 1 }}
                fontWeight="600"
              >
                <ThumbUpOffAltIcon fontSize="small" sx={{ mb: -0.5 }} /> Yes
              </Typography>
            ) : description.match_type ===
              APIApplicationDescriptionMatchType.PARTIAL_MATCH ? (
              <Typography
                sx={{ color: theme.palette.warning.light, ml: 1 }}
                fontWeight="600"
              >
                <ThumbsUpDownIcon fontSize="small" /> Partially
              </Typography>
            ) : (
              <Typography
                sx={{ color: theme.palette.error.light, ml: 1 }}
                fontWeight="600"
              >
                <ThumbDownOffAltIcon fontSize="small" sx={{ mb: -0.75 }} /> No
              </Typography>
            )}
          </Box>
        </Grid>
      ) : (
        <Box>Not sent</Box>
      )}
    </Box>
  );
}
