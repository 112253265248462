import {
  IconButton,
  Modal,
  TextField,
  Box,
  Typography,
  TableBody,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  Chip,
  Tooltip,
  Link,
  TableHead,
  Button,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

import { LoadingButton } from "@mui/lab";
import { useState, FormEvent } from "react";

import { useTrueBizApi } from "../../api";
import NetworkFailureToast from "../NetworkFailureToast";
import APIFoundDomain from "../../types/APIFoundDomain";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 1000,
  maxHeight: "95%",
  bgcolor: "background.paper",
  borderRadius: 1,
  overflow: "auto",
  outline: "none",
  boxShadow: 24,
  lineHeight: 1,
  p: 2.5,
};

function Response({
  foundDomains,
  setResponse,
  onExit,
}: {
  foundDomains: APIFoundDomain[];
  setResponse: (resp: any) => void;
  onExit: () => void;
}) {
  return (
    <>
      <Box>
        <IconButton onClick={() => setResponse(null)} color="primary">
          <ArrowBackIcon fontSize="inherit" sx={{ margin: 0 }} />
        </IconButton>{" "}
        <IconButton onClick={onExit} color="primary" sx={{ float: "right" }}>
          <CloseIcon fontSize="inherit" sx={{ margin: 0 }} />
        </IconButton>{" "}
      </Box>
      {foundDomains.length === 0 ? (
        <Box sx={{ ml: 1, mr: 1, mt: 2, mb: 2 }}>
          <Typography variant="h5" component="h5" sx={{ mb: 2 }}>
            No domain candidates found.
          </Typography>
          <Box>
            For best results, enter as many fields as possible to the form.
          </Box>
        </Box>
      ) : (
        <TableContainer>
          <Table size="small" aria-label="contact info table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Domain</strong>
                </TableCell>

                <TableCell>
                  <strong>Name</strong>
                </TableCell>

                <TableCell>
                  <strong>Sources</strong>
                </TableCell>

                <TableCell align="right">
                  <strong>Actions</strong>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {foundDomains.map((foundDomain: any) => (
                <TableRow
                  key={foundDomain.name || foundDomain.domain}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell sx={{ pr: 0 }}>
                    <Link
                      href={`https://${foundDomain.domain}`}
                      target="_blank"
                      rel="noopener"
                    >
                      <Typography variant="subtitle1">
                        {foundDomain.domain}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell sx={{ pr: 0 }}>{foundDomain.name}</TableCell>
                  <TableCell sx={{ pl: 0 }} align="right">
                    {foundDomain.sources.map((source: any) => (
                      <Box
                        key={JSON.stringify(source)}
                        sx={{
                          display:
                            foundDomain.sources.length > 2
                              ? "block"
                              : "inline-block",
                        }}
                      >
                        <Tooltip
                          key={source.type}
                          title={source.type}
                          placement="right"
                          arrow
                        >
                          <Chip
                            sx={{ ml: 1, mt: 0.5 }}
                            label={<Box>{source.type}</Box>}
                            color="info"
                            variant="outlined"
                          />
                        </Tooltip>
                      </Box>
                    ))}
                  </TableCell>
                  <TableCell>
                    <Link
                      href={`${window.location.origin}/domain/${foundDomain.domain}?run=1`}
                      target="_blank"
                    >
                      Run
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

export default function DomainFinder({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const api = useTrueBizApi();
  const [loading, setLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [response, setResponse] = useState<any | null>(null);
  const [businessName, setBusinessName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [addressLine1, setAddressLine1] = useState<string>("");
  const [addressLine2, setAddressLine2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [stateProvince, setStateProvince] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");
  const [country, setCountry] = useState<string>("");

  const onSubmitForm = async (e: FormEvent) => {
    e.preventDefault();

    try {
      setResponse(null);
      setLoading(true);
      const response = await api.getDomainFinder(
        email,
        phone,
        businessName,
        addressLine1,
        addressLine2,
        city,
        stateProvince,
        postalCode,
        country
      );
      setResponse(response);
    } catch (e) {
      setFailed(true);
    } finally {
      setLoading(false);
    }
  };

  const clearForm = () => {
    setAddressLine1("");
    setAddressLine2("");
    setBusinessName("");
    setEmail("");
    setCity("");
    setCountry("");
    setStateProvince("");
    setPostalCode("");
    setPhone("");
  };

  const onExit = () => {
    onClose();
    setResponse(null);
  };

  return (
    <Box>
      <Modal
        open={open}
        onClose={onExit}
        aria-labelledby="screenshot-modal-title"
        aria-describedby="screenshot-modal-description"
      >
        <Box sx={modalStyle}>
          {response ? (
            <Response
              foundDomains={response}
              setResponse={setResponse}
              onExit={onExit}
            />
          ) : (
            <Box width="400px">
              <Typography
                variant="h6"
                fontWeight={600}
                component="h3"
                sx={{ mb: 0 }}
              >
                Domain Finder
                <IconButton
                  onClick={onClose}
                  color="primary"
                  sx={{ float: "right", mt: -0.7 }}
                >
                  <CloseIcon fontSize="inherit" sx={{ margin: 0 }} />
                </IconButton>{" "}
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    float: "right",
                    mr: 1,
                  }}
                  onClick={clearForm}
                >
                  Clear Form
                </Button>
              </Typography>
              <Typography variant="caption" mb={1}>
                Search for websites associated with known business details.
              </Typography>
              <Box
                component="form"
                onSubmit={onSubmitForm}
                sx={{
                  mt: 3,
                  pr: 2,
                  width: "100%",
                  "> div": { margin: 1 },
                  "> button": { margin: 1 },
                }}
              >
                <TextField
                  fullWidth
                  disabled={loading}
                  label="Business Name"
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  type="text"
                  size="small"
                />
                <TextField
                  fullWidth
                  disabled={loading}
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  size="small"
                />
                <TextField
                  fullWidth
                  disabled={loading}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  label="Phone"
                  type="text"
                  size="small"
                />
                <TextField
                  fullWidth
                  disabled={loading}
                  label="Address Line 1"
                  value={addressLine1}
                  onChange={(e) => setAddressLine1(e.target.value)}
                  type="text"
                  size="small"
                />
                <TextField
                  fullWidth
                  disabled={loading}
                  value={addressLine2}
                  onChange={(e) => setAddressLine2(e.target.value)}
                  label="Address Line 2"
                  type="text"
                  size="small"
                />
                <TextField
                  fullWidth
                  disabled={loading}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  label="City"
                  type="text"
                  size="small"
                />
                <TextField
                  fullWidth
                  disabled={loading}
                  value={stateProvince}
                  onChange={(e) => setStateProvince(e.target.value)}
                  label="State / Province"
                  type="text"
                  size="small"
                />
                <TextField
                  fullWidth
                  disabled={loading}
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                  label="Postal Code"
                  type="text"
                  size="small"
                />
                <TextField
                  fullWidth
                  disabled={loading}
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  label="Country"
                  type="text"
                  size="small"
                />
                <LoadingButton
                  variant="contained"
                  sx={{ width: "100%" }}
                  disabled={loading}
                  loading={loading}
                  type="submit"
                >
                  Submit
                </LoadingButton>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
      <NetworkFailureToast open={failed} onClose={() => setFailed(false)} />
    </Box>
  );
}
