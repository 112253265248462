import { HTMLProps, ReactNode, useState, Fragment } from "react";
import cn from "classnames";
import Highlighter from "react-highlight-words";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useOverflowDetector } from "react-detectable-overflow";

import {
  Box,
  Divider,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { isEqual, orderBy, uniq } from "lodash";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import LoadingButton from "@mui/lab/LoadingButton";

import {
  formatDate,
  formatDateTime,
  formatStatus,
  formatResolutionType,
} from "./utils";

import trimHighlightContext from "./trimHighlightContext";

import LinkButton from "./LinkButton";
import Whisker from "./Whisker";
import ResolveButton from "./ResolveButton";

import styles from "./IssueDetail.module.css";
import { useTrueBizApi } from "../../../api";

import { useIdentity } from "../../IdentityProvider";
import { useShowNetworkFailureToast } from "../../NetworkFailureToast";
import APIMonitoringAnomaly, {
  APIAnomalyActivityType,
  APIAnomalyStatus,
} from "../../../types/APIAnomaly";
import { APIMonitoringEnrollment } from "../../../types/APIMonitoringEnrollment";

const Highlight = ({
  children,
}: {
  children: ReactNode;
  highlightIndex: number;
}) => <strong className={styles["highlighted-text"]}>{children}</strong>;

export interface Props extends Exclude<HTMLProps<HTMLDivElement>, "children"> {
  issue: APIMonitoringAnomaly;
  suspendLoading?: boolean;
  focused?: boolean;
}

export default function IssueDetail({
  issue,
  suspendLoading = false,
  focused = false,
  className,
  ...etc
}: Props) {
  const api = useTrueBizApi();
  const identity = useIdentity();
  const queryClient = useQueryClient();
  const showToast = useShowNetworkFailureToast();
  const [expanded, setExpanded] = useState(false);

  const [commentText, setCommentText] = useState("");

  const { ref: overflowRef, overflow } = useOverflowDetector();

  const allPeopleQuery = useQuery({
    queryKey: ["allPeople"],
    queryFn: () => api.getAnomaliesMetaAssignableUsers(),
  });

  const commentMutation = useMutation({
    mutationKey: ["issue", "comment", issue.id, commentText],
    mutationFn: () => api.addAnomalyComment(issue.id, commentText),
    onSuccess: () => {
      setCommentText("");

      queryClient.invalidateQueries({
        queryKey: ["getNotification", issue.id],
      });

      queryClient.invalidateQueries({
        queryKey: ["getMonitor", issue.monitors[0]?.domain],
      });
    },
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: ["getNotification", issue.id],
      });

      await queryClient.cancelQueries({
        queryKey: ["getMonitor", issue.monitors[0]?.domain],
      });

      const previousNotification = queryClient.getQueryData([
        "getNotification",
        issue.id,
      ]);

      const previousMonitor = queryClient.getQueryData([
        "getMonitor",
        issue.monitors[0]?.domain,
      ]);

      const now = new Date().toISOString();
      const me = (allPeopleQuery.data || []).filter(
        (p) => p.email != null && p.email === identity.user?.email
      )[0];

      if (previousNotification) {
        queryClient.setQueryData(
          ["getNotification", issue.id],
          (old: APIMonitoringAnomaly) => {
            const updated = {
              ...old,
              comments: [
                ...old.comments,
                {
                  id: "PENDING",
                  created_at: now,
                  updated_at: now,
                  commenter: me || { id: "PENDING", email: null },
                  message: commentText,
                },
              ],
              activity: [
                ...old.activity,
                {
                  id: "PENDING",
                  type: APIAnomalyActivityType.COMMENTED,
                  created_at: now,
                  comment: {
                    id: "PENDING",
                    created_at: now,
                    updated_at: now,
                    commenter: me || { id: "PENDING", email: null },
                    message: commentText,
                  },
                },
              ],
            };

            return updated;
          }
        );
      }

      if (previousMonitor) {
        queryClient.setQueryData(
          ["getMonitor", issue.monitors[0]?.domain],
          (old: APIMonitoringEnrollment) => {
            const updated = {
              ...old,
              anomalies: old.anomalies.map((anomaly) => {
                if (anomaly.id !== issue.id) return anomaly;
                return {
                  ...anomaly,
                  comments: [
                    ...anomaly.comments,
                    {
                      id: "PENDING",
                      created_at: now,
                      updated_at: now,
                      commenter: me || { id: "PENDING", email: null },
                      message: commentText,
                    },
                  ],
                  activity: [
                    ...anomaly.activity,
                    {
                      id: "PENDING",
                      type: APIAnomalyActivityType.COMMENTED,
                      created_at: now,
                      comment: {
                        id: "PENDING",
                        created_at: now,
                        updated_at: now,
                        commenter: me || { id: "PENDING", email: null },
                        message: commentText,
                      },
                    },
                  ],
                };
              }),
            };

            return updated;
          }
        );
      }

      return { previousNotification, previousMonitor };
    },
    onError: (err, variables, context) => {
      if (context?.previousNotification) {
        queryClient.setQueryData(
          ["getNotification", issue.id],
          context.previousNotification
        );
      }

      if (context?.previousMonitor) {
        queryClient.setQueryData(
          ["getMonitor", issue.monitors[0]?.domain],
          context.previousMonitor
        );
      }

      showToast();
    },
  });

  return (
    <div
      {...etc}
      className={cn(styles.issue, className, {
        [styles.unresolved]: issue.status !== APIAnomalyStatus.RESOLVED,
        [styles.focused]: focused,
      })}
      onClick={() => setExpanded(true)}
    >
      <div className={styles.badge}>
        <div className={styles.date}>{formatDateTime(issue.created_at)}</div>
        <div className={styles.status}>{formatStatus(issue.status)}</div>
      </div>
      <div className={styles.controls}>
        <div className={styles.actionButtons}>
          <LinkButton title="Copy link" issueId={issue.id} />
          <ResolveButton
            title={
              issue.status === APIAnomalyStatus.RESOLVED
                ? "This issue has already been resolved"
                : "Resolve issue"
            }
            issueId={issue.id}
            monitorDomain={issue.monitors[0]?.domain}
            disabled={issue.status === APIAnomalyStatus.RESOLVED}
          />
        </div>
      </div>

      <div
        className={styles.details}
        style={
          !expanded
            ? { height: "450px", overflow: "hidden" }
            : { minHeight: "450px", height: "auto" }
        }
        ref={overflowRef as any}
      >
        <div className={styles.summary}>
          {issue.detections.map((detection) => {
            return (
              <div key={detection.id}>
                <div className={styles.type}>
                  Alert type{detection.alerts.length === 1 ? "" : "s"}:{" "}
                  <strong>
                    {uniq(orderBy(detection.alerts.map((a) => a.display))).join(
                      ", "
                    ) || "unknown"}
                  </strong>
                </div>

                {/* We tried our best */}
                {detection.links.length === 0 && (
                  <Typography mt={2}>
                    No further information about this issue is currently
                    available.
                  </Typography>
                )}

                {/* No snippets, just vom up the list of urls */}
                {detection.links.length > 0 &&
                  detection.snippets.length === 0 && (
                    <Stack>
                      <div>
                        Related content was found on the following pages:
                      </div>
                      <ul
                        style={{
                          paddingLeft: "1em",
                          paddingRight: "2.75em",
                          wordBreak: "break-word",
                        }}
                      >
                        {detection.links.map((link) => (
                          <li key={link.id} className={styles.citation}>
                            <a
                              href={link.href}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {link.display || link.href}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </Stack>
                  )}

                {/* Show snippets and links */}
                {detection.links.length > 0 &&
                  detection.snippets.length > 0 && (
                    <Stack>
                      <div>
                        Related content was found on the following pages:
                      </div>
                      <ul
                        style={{
                          paddingLeft: "1em",
                          paddingRight: "2.75em",
                          wordBreak: "break-word",
                        }}
                      >
                        {detection.snippets.map((snippet) => {
                          return (
                            <li key={snippet.id} className={styles.citation}>
                              {snippet.direct_links.map(
                                (snippetLink, i, snippetLinks) => {
                                  return (
                                    <Fragment key={snippetLink.id}>
                                      <a
                                        href={snippetLink.href}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {snippetLink.display ||
                                          snippetLink.href}
                                      </a>
                                      {i !== snippetLinks.length - 1 && <br />}
                                    </Fragment>
                                  );
                                }
                              )}
                              {snippet.highlights.length === 0 && (
                                <div className={styles.context}>
                                  <div className={styles.soloContext}>
                                    {snippet.context}
                                  </div>
                                </div>
                              )}
                              {snippet.highlights.map((snippetHighlight) => {
                                return (
                                  <div
                                    key={snippetHighlight.id}
                                    className={styles.context}
                                  >
                                    <div className={styles.quote}>
                                      <Highlighter
                                        searchWords={[snippetHighlight.preview]}
                                        textToHighlight={trimHighlightContext(
                                          snippet.context,
                                          [snippetHighlight.preview]
                                        )}
                                        highlightTag={Highlight}
                                        autoEscape
                                      />
                                    </div>
                                    <div className={styles.firstSeen}>
                                      {/* TODO: actually track this on the backend */}
                                      First seen {formatDate(issue.created_at)}
                                    </div>
                                  </div>
                                );
                              })}
                            </li>
                          );
                        })}
                      </ul>
                    </Stack>
                  )}
              </div>
            );
          })}
        </div>
        <Divider orientation="vertical" flexItem />
        <Box
          pl={2}
          pr={2}
          flex="1"
          maxWidth="45%"
          marginLeft="auto"
          marginRight="auto"
        >
          <Typography variant="subtitle1" mb={2} fontWeight={600}>
            Comments and activity
          </Typography>
          {allPeopleQuery.isLoading ? (
            <Stack mb={2}>
              <Skeleton />
              <Skeleton />
              <Whisker />
              <Skeleton />
            </Stack>
          ) : (
            <Stack minHeight="25px" gap="0.25em">
              {issue.activity
                .map((e) => {
                  if (e.type === APIAnomalyActivityType.CREATED) {
                    return (
                      <Stack key={e.id}>
                        <Stack
                          direction="row"
                          gap="1em"
                          alignItems="baseline"
                          justifyContent="space-between"
                        >
                          <Typography variant="subtitle2">
                            Issue reported by TrueBiz
                          </Typography>
                          <Typography
                            className={styles.timestamp}
                            variant="subtitle2"
                          >
                            {formatDateTime(e.created_at)}
                          </Typography>
                        </Stack>
                      </Stack>
                    );
                  }

                  if (e.type === APIAnomalyActivityType.RESOLVED) {
                    return (
                      <Stack key={e.id}>
                        <Stack
                          direction="row"
                          gap="1em"
                          alignItems="baseline"
                          justifyContent="space-between"
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{
                              opacity: e.id === "PENDING" ? 0.5 : 1,
                            }}
                          >
                            Issue resolved
                          </Typography>
                          <Typography
                            className={styles.timestamp}
                            variant="subtitle2"
                          >
                            {e.id === "PENDING"
                              ? "Pending..."
                              : formatDateTime(e.resolution.created_at)}
                          </Typography>
                        </Stack>
                        <Typography
                          mt={1}
                          p={1.5}
                          mb={1.5}
                          border="4px double #756f8b"
                          borderRadius="4px"
                          component="div"
                          sx={{
                            opacity: e.id === "PENDING" ? 0.5 : 1,
                          }}
                        >
                          <Typography>
                            <strong>
                              {e.resolution.resolved_by?.email ||
                                "A TrueBiz user"}
                            </strong>{" "}
                            resolved the issue as{" "}
                            {formatResolutionType(e.resolution.type)}.
                          </Typography>
                          {e.resolution.description && (
                            <Typography mt={1}>
                              &ldquo;{e.resolution.description}&rdquo;
                            </Typography>
                          )}
                        </Typography>
                      </Stack>
                    );
                  }

                  if (e.type === APIAnomalyActivityType.COMMENTED) {
                    return (
                      <Stack key={e.id}>
                        <Stack
                          direction="row"
                          gap="1em"
                          alignItems="baseline"
                          justifyContent="space-between"
                        >
                          <Typography variant="subtitle2">
                            <strong>
                              {e.comment.commenter.email || "A TrueBizUser"}
                            </strong>{" "}
                            commented:
                          </Typography>
                          <Typography
                            className={styles.timestamp}
                            variant="subtitle2"
                          >
                            {e.id === "PENDING"
                              ? "Pending...."
                              : formatDateTime(e.created_at)}
                          </Typography>
                        </Stack>
                        <div
                          className={cn(styles.commentBody, {
                            [styles.pending]: e.id === "PENDING",
                          })}
                        >
                          {e.comment.message}
                        </div>
                      </Stack>
                    );
                  }

                  if (e.type === APIAnomalyActivityType.ASSIGNED) {
                    if (!e.assignment.assigned_to) {
                      return (
                        <Stack key={e.id}>
                          <Stack
                            direction="row"
                            gap="1em"
                            alignItems="baseline"
                            justifyContent="space-between"
                          >
                            <Typography variant="subtitle2">
                              <strong>
                                {e.assignment.assigned_by?.email ||
                                  "A TrueBiz user"}
                              </strong>{" "}
                              unassigned issue
                            </Typography>
                            <Typography
                              className={styles.timestamp}
                              variant="subtitle2"
                            >
                              {formatDateTime(e.created_at)}
                            </Typography>
                          </Stack>
                        </Stack>
                      );
                    }

                    if (
                      isEqual(
                        e.assignment.assigned_by,
                        e.assignment.assigned_to
                      )
                    ) {
                      return (
                        <Stack key={e.id}>
                          <Stack
                            direction="row"
                            gap="1em"
                            alignItems="baseline"
                            justifyContent="space-between"
                          >
                            <Typography variant="subtitle2">
                              <strong>
                                {e.assignment.assigned_by?.email ||
                                  "A TrueBiz user"}
                              </strong>{" "}
                              self-assigned issue
                            </Typography>
                            <Typography
                              className={styles.timestamp}
                              variant="subtitle2"
                            >
                              {formatDateTime(e.created_at)}
                            </Typography>
                          </Stack>
                        </Stack>
                      );
                    }

                    if (e.assignment.assigned_by && e.assignment.assigned_to) {
                      return (
                        <Stack key={e.id}>
                          <Stack
                            direction="row"
                            gap="1em"
                            alignItems="baseline"
                            justifyContent="space-between"
                          >
                            <Typography variant="subtitle2">
                              <strong>
                                {e.assignment.assigned_by?.email ||
                                  "A TrueBiz user"}
                              </strong>{" "}
                              assigned issue to{" "}
                              <strong>
                                {e.assignment.assigned_to?.email ||
                                  "a TrueBiz user"}
                              </strong>
                            </Typography>
                            <Typography
                              className={styles.timestamp}
                              variant="subtitle2"
                            >
                              {formatDateTime(e.created_at)}
                            </Typography>
                          </Stack>
                        </Stack>
                      );
                    }
                  }

                  if (process.env.NODE_ENV === "development") {
                    console.error(
                      `Found unexpected ${e.type} activity with id ${e.id} on anomaly ${issue.id}`
                    );
                  }

                  return null;
                })
                .flatMap((el, i, els) =>
                  i === els.length - 1 || !el ? [el] : [el, <Whisker key={i} />]
                )}
            </Stack>
          )}
          <Stack mt={2}>
            <Stack>
              <TextField
                sx={{ width: "100%" }}
                multiline
                rows={3}
                value={commentText}
                onChange={(e) => {
                  setCommentText(e.target.value);
                }}
                disabled={commentMutation.isPending || allPeopleQuery.isLoading}
              />
            </Stack>
            <LoadingButton
              variant="outlined"
              sx={{
                display: "block",
                marginTop: "1em",
                marginLeft: "auto",
              }}
              disabled={allPeopleQuery.isLoading}
              loading={commentMutation.isPending}
              onClick={async () => {
                if (!commentText) return;
                await commentMutation.mutateAsync();
              }}
            >
              Add Comment
            </LoadingButton>
          </Stack>
        </Box>
      </div>

      {overflow && (
        <div className={styles.seeMore} onClick={() => setExpanded(true)}>
          <ExpandMoreIcon /> See more&hellip;
        </div>
      )}
    </div>
  );
}
