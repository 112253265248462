import { ReactNode } from "react";
import { Alert, Button, Box } from "@mui/material";
import { Link } from "react-router-dom";

export default function ResponseErrorDisplay({
  callToActionComponent = null,
  lastRequestStatus,
  domain,
}: {
  callToActionComponent?: ReactNode | null;
  lastRequestStatus: number | null;
  domain?: string | null | undefined;
}) {
  if (lastRequestStatus === 404) {
    return (
      <Alert severity="error" sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            flex: 1,
            alignItems: "center",
          }}
        >
          <span>
            We couldn't find{" "}
            {domain ? (
              <>any searches for {domain}</>
            ) : (
              "the search you requested"
            )}
          </span>
          <Button
            component={Link}
            to={`/?domain=${domain}`}
            size="small"
            variant="outlined"
            sx={{ ml: 2 }}
          >
            Search {domain ? <>for {domain}</> : ""} now
          </Button>
        </Box>
      </Alert>
    );
  }
  const isRequestUnsuccessful =
    lastRequestStatus && (lastRequestStatus > 401 || lastRequestStatus === 400);

  if (!isRequestUnsuccessful) return null;

  return (
    <Alert severity="warning" sx={{ mt: 3 }}>
      There was an error when submitting the request.
      {callToActionComponent && <> {callToActionComponent}</>}
    </Alert>
  );
}
