import {
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useCloseIssueDetailModal, useInspectIssueIds } from "./hooks";
import IssueDetails from "./IssueDetails";
import MonitorTimeline from "../MonitorTimeline";

export default function IssueDetailModal() {
  const { issueId, monitorDomain } = useInspectIssueIds();
  const close = useCloseIssueDetailModal();

  return (
    <Dialog
      open={issueId != null || monitorDomain != null}
      onClose={() => {
        close();
      }}
      fullWidth
      maxWidth="lg"
      scroll="paper"
      keepMounted={false}
      PaperProps={{
        sx: {
          height: "90vh",
          minHeight: "90vh",
        },
      }}
    >
      <DialogTitle
        style={{
          textAlign: "right",
          paddingBottom: 0,
          marginBottom: "0.75em",
          height: 0,
          position: "relative",
          zIndex: 99,
        }}
      >
        <IconButton
          onClick={() => {
            close();
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {issueId ? (
          <IssueDetails issueId={issueId} monitorDomain={monitorDomain} />
        ) : monitorDomain ? (
          <MonitorTimeline monitorDomain={monitorDomain} />
        ) : (
          <Stack>
            <Typography variant="h5">
              There was an error loading the requested issue.
            </Typography>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
}
