import { useFormik } from "formik";
import {
  Button,
  Divider,
  Stack,
  Typography,
  TextField,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Switch,
  FormControlLabel,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { MonitoringCreateFormSchema } from "./schema";
import MonitoringIcon from "./monitoring-icon.svg";
import FrequencySelect from "./FrequencySelect";
import { Prefill } from "../types";
import APICompanyMatchProfile from "../../../../types/APICompanyMatchProfile";
import {
  APIMonitoringEnrollmentInput,
  APIMonitoringEnrollment,
  APIMonitorType,
} from "../../../../types/APIMonitoringEnrollment";

export type FormState = ReturnType<
  typeof MonitoringCreateFormSchema.validateSync
>;

export interface Props {
  onCancel: () => void;
  onSuccess: (domain: string, nextRun?: string | null | undefined) => void;
  onError: (domain: string, reason?: string) => void;
  onCreateMonitor: (
    formData: APIMonitoringEnrollmentInput
  ) => Promise<APIMonitoringEnrollment>;
  prefill?: Partial<Prefill>;
  matchProfiles: APICompanyMatchProfile[];
  withConnectedEntities?: boolean;
}

export default function MonitorCreateForm({
  onCancel,
  onSuccess,
  onError,
  onCreateMonitor,
  prefill,
  matchProfiles,
  withConnectedEntities = false,
}: Props) {
  const formik = useFormik({
    initialValues: {
      domain: "",
      closure_selected: true,
      closure_frequency_days: 30,
      content_selected: true,
      content_frequency_days: 30,
      connected_entities_selected: withConnectedEntities ? true : false,
      connected_entities_frequency_days: withConnectedEntities ? 30 : undefined,
      external_ref_id: "",
      profile_id: "",
      ...(prefill || {}),
    },
    onSubmit: async (values: FormState, { resetForm }) => {
      try {
        // Formik will not automatically honor yup's value transforms which we need
        // to potentially clean and extract the domain/hostname from a url
        const transformedValues = MonitoringCreateFormSchema.cast(values);

        const creationInput: any = {
          domain: transformedValues.domain,
          monitors: [],
        };

        creationInput.domain = transformedValues.domain;
        if (transformedValues.external_ref_id) {
          creationInput.external_ref_id = transformedValues.external_ref_id;
        }

        if (transformedValues.profile_id) {
          creationInput.profile_id = transformedValues.profile_id;
        }

        if (transformedValues.closure_selected) {
          creationInput.monitors.push({
            type: APIMonitorType.BUSINESS_CLOSURE,
            ...(transformedValues.closure_frequency_days
              ? {
                  frequency: { days: transformedValues.closure_frequency_days },
                }
              : {}),
          });
        }

        if (transformedValues.content_selected) {
          creationInput.monitors.push({
            type: APIMonitorType.WEBSITE_CONTENT,
            ...(transformedValues.content_frequency_days
              ? {
                  frequency: { days: transformedValues.content_frequency_days },
                }
              : {}),
          });
        }

        if (transformedValues.connected_entities_selected) {
          creationInput.monitors.push({
            type: APIMonitorType.CONNECTED_ENTITIES,
            ...(transformedValues.connected_entities_frequency_days
              ? {
                  frequency: {
                    days: transformedValues.connected_entities_frequency_days,
                  },
                }
              : {}),
          });
        }

        const result = await onCreateMonitor(creationInput);
        resetForm();
        onSuccess(transformedValues.domain, result.next_run);
      } catch (e) {
        let description = "An unknown error";
        try {
          if (e instanceof Response) {
            const j = await e.json();
            description = j?.message || JSON.stringify(j);
          }
        } catch (e) {
          // pass
        }

        onError(values.domain, description);
      }
    },
    validationSchema: MonitoringCreateFormSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack
        sx={{
          alignItems: "center",
          mb: "2em",
        }}
      >
        <img src={MonitoringIcon} width={100} alt="" />
        <Typography>
          Enroll a merchant in
          <br />
          TrueBiz monitoring
        </Typography>
      </Stack>
      <Stack
        sx={{
          gap: "1em",
        }}
      >
        <FormControl>
          <TextField
            fullWidth
            name="domain"
            label="Domain (required)"
            required
            onChange={formik.handleChange}
            value={formik.values.domain}
            error={!!(formik.touched.domain && formik.errors.domain)}
            autoFocus
          />
          {formik.errors.domain && formik.touched.domain && (
            <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
              {formik.errors.domain}
            </Alert>
          )}
        </FormControl>

        <Typography variant="subtitle1">Alert me for</Typography>
        {formik.dirty && (formik.errors as any)?.monitor_selection && (
          <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
            {(formik.errors as any)?.monitor_selection}
          </Alert>
        )}
        <Box pl={1}>
          <FormControlLabel
            label="Business closure"
            control={
              <Switch
                name="closure_selected"
                checked={formik.values.closure_selected}
                onChange={formik.handleChange}
              />
            }
          />
          {formik.values.closure_selected && (
            <Box pl={6} pr={6}>
              <FrequencySelect
                value={formik.values.closure_frequency_days}
                onChange={formik.handleChange}
                name="closure_frequency_days"
              />
            </Box>
          )}
          <FormControlLabel
            label="Content changing to high risk"
            control={
              <Switch
                name="content_selected"
                checked={formik.values.content_selected}
                onChange={formik.handleChange}
              />
            }
          />
          {formik.values.content_selected && (
            <Box pl={6} pr={6}>
              <FrequencySelect
                value={formik.values.content_frequency_days}
                onChange={formik.handleChange}
                name="content_frequency_days"
              />
            </Box>
          )}
          {withConnectedEntities && (
            <FormControlLabel
              label="Changes in connected entities"
              control={
                <Switch
                  name="connected_entities_selected"
                  checked={formik.values.connected_entities_selected}
                  onChange={formik.handleChange}
                />
              }
            />
          )}
          {withConnectedEntities &&
            formik.values.connected_entities_selected && (
              <Box pl={6} pr={6}>
                <FrequencySelect
                  value={formik.values.connected_entities_frequency_days}
                  onChange={formik.handleChange}
                  name="connected_entities_frequency_days"
                />
              </Box>
            )}
        </Box>

        <Divider />
        <details>
          <summary style={{ cursor: "pointer" }}>Advanced Settings</summary>
          <Stack pt={5} gap={2}>
            {matchProfiles?.length > 0 && (
              <FormControl sx={{ width: "100%" }}>
                <InputLabel
                  id="match-profile-label"
                  shrink
                  sx={{ background: "white" }}
                >
                  Risk Profile
                </InputLabel>
                <Select
                  labelId="match-profile-label"
                  label="Risk Profile"
                  id="match-profile-select"
                  defaultValue={formik.initialValues.profile_id}
                  value={
                    formik.values.profile_id ? formik.values.profile_id : ""
                  }
                  onChange={formik.handleChange}
                  name="profile_id"
                  displayEmpty
                >
                  <MenuItem value={""}>TrueBiz Default</MenuItem>
                  {matchProfiles?.map((profile) => (
                    <MenuItem key={profile.id} value={profile.id}>
                      {profile.name}
                    </MenuItem>
                  ))}
                </Select>
                {formik.errors.profile_id && formik.touched.profile_id && (
                  <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
                    {formik.errors.profile_id}
                  </Alert>
                )}
              </FormControl>
            )}

            <FormControl>
              <TextField
                fullWidth
                name="external_ref_id"
                label="External Reference ID"
                onChange={formik.handleChange}
                value={formik.values.external_ref_id}
                error={
                  !!(
                    formik.touched.external_ref_id &&
                    formik.errors.external_ref_id
                  )
                }
              />
              {formik.errors.external_ref_id &&
                formik.touched.external_ref_id && (
                  <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
                    {formik.errors.external_ref_id}
                  </Alert>
                )}
            </FormControl>
          </Stack>
        </details>
      </Stack>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          width: "100%",
          gap: "1.5em",
          marginTop: "1.5em",
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            formik.resetForm();
            onCancel();
          }}
          disabled={formik.isSubmitting}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={formik.isSubmitting}
        >
          Enroll Merchant
        </LoadingButton>
      </div>
    </form>
  );
}
