export enum DataSource {
  SOCIAL = "social",
  SUBMITTED = "submitted",
  WEBSITE = "website",
  OTHER = "other",
}

export enum APIApplicationDescriptionMatchType {
  NO_MATCH = "No match",
  PARTIAL_MATCH = "Partial match",
  FULL_MATCH = "Full match",
}

export default interface APIApplication {
  email_business_connections?: DataSource[] | null;
  phone_business_connections?: DataSource[] | null;
  address_business_connections?: DataSource[] | null;
  person_business_connections?: DataSource[] | null;
  description?: {
    text: string;
    match_type: APIApplicationDescriptionMatchType;
  } | null;
}
